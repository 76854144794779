import React, { useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import DotMenu from '../Elements/DotMenu'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import Grixis from '../Grixis'
import Loader from '../Elements/Loader'
import SubjectGrix from '../SubjectGrix'
import SubjectGrixRow from '../SubjectGrixRow'

import model from '../../model'

import { ReactComponent as LocationIcon } from '../../icon/location.svg'

export default function LocationsPage() {

    const navigate = useNavigate()

    const [subjectFocus, setSubjectFocus] = useState(null)
    const [loading, setLoading] = useState(false)

    const [curPage, setCurPage] = useState(1)
    const pagesLoading = useRef([])
    const pagesLoadingHeight = useRef({})

    const refBottom = useRef()

    const isAdmin = useSelector(state => model.selector('member.isAdmin', { state }))
    const recPages = useSelector(state => model.selector('location.recPages', { state }))

    const actions = []

    if (isAdmin){
        actions.push({
            label: 'add location',
            onClick: () => {
                navigate('/locations/add')
            }
        })
    }
    

    const handleClose = ({ subject }) => {
        setSubjectFocus(null)
    }

    const handleSubjectClick = ({ subject }) => {
        setSubjectFocus({
            subject
        })
    }

    useEffect(()=>{
        pagesLoadingHeight.current[curPage] = window.innerHeight
    }, []) //eslint-disable-line

    useEffect(() => {
        const onScroll = () => {
            const rect = refBottom.current.getBoundingClientRect();
            const height = window.innerHeight
            const contentHeight = refBottom.current.offsetTop

            if (height + 100 > rect.top) {
                const nextPage = curPage + 1

                pagesLoadingHeight.current = pagesLoadingHeight.current || {}

                if (pagesLoadingHeight.current[curPage] >= contentHeight) return

                if (!pagesLoadingHeight.current[nextPage]){
                    pagesLoadingHeight.current[nextPage] = contentHeight

                    setCurPage(nextPage)
                }
            }
            //console.log(window.scrollY, rect.top)
        }
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [curPage])

    useEffect(() => {
        if (recPages[curPage]) return

        if (curPage > 1 && (!recPages[curPage - 1] || recPages[curPage - 1].length < 1)) return

        if (pagesLoading.current && pagesLoading.current.includes(curPage)) return
        pagesLoading.current.push(curPage);

        (async () => {
            try {
                setLoading(true)
                await model.action('location.getRecsForPage', { page: curPage })
                setLoading(false)
            } catch (e) {
                setLoading(false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [curPage, recPages])

    const renderPages = () => {
        let subjects = []
        for (let i = 1; i <= curPage; i++) {
            if (!recPages[i]) break;
            let subjectsPage = recPages[i].map(rec => {
                const size = [1, 1]

                const subject = model.helper('location.getSubjectFromRec', {rec})

                if (subjectFocus && (model.helper('subject.isMatch', { subject1: subject, subject2: subjectFocus.subject }))) {
                    return (
                        <React.Fragment key={`group-${subject.model}~${subject.id}`}>
                            <SubjectGrix
                                handleClick={handleSubjectClick}
                                key={`grix-${subject.model}~${subject.id}`}
                                subject={subject}
                                selected={true}
                                size={size} />
                            <SubjectGrixRow
                                handleClose={handleClose}
                                key={`row~${subject.model}~${subject.id}`}
                                intent={subjectFocus.intent}
                                subject={subject} />
                        </React.Fragment>
                    )
                }
                return (
                    <SubjectGrix
                        key={`${subject.model}~${subject.id}`}
                        deselected={subjectFocus ? true : false}
                        handleClick={handleSubjectClick}
                        subject={subject}
                        size={size}
                    />
                )
            })

            subjects = [...subjects, ...subjectsPage]
        }
        return subjects
    }

    return (
        <Frame>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <LocationIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    locations
                </div>
                <div className="">
                    <DotMenu options={actions} direction="right" theme="light" />
                </div>
            </FrameTitle>
            <div className="bg-slate-900">
                <Grixis>
                    {renderPages()}
                </Grixis>
            </div>
            {loading && (
                <div className="h-[300px] relative">
                    <div className={`${loading ? 'opacity-100' : 'opacity-0 pointer-events-none'} flex flex-row items-center justify-center absolute top-0 bottom-0 left-0 right-0 bg-slate-900/80`}>
                        <Loader />
                    </div>
                </div>
            )}

            <div ref={refBottom} />
        </Frame>
    )
}