import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import model from '../../model'

import { ReactComponent as ChapterIcon } from '../../icon/chapter.svg'
import { ReactComponent as CloseIcon } from '../../icon/close.svg'
import { ReactComponent as EditIcon } from '../../icon/edit-icon.svg'

import Button from '../Elements/Button'
import ButtonIcon from '../Elements/ButtonIcon'
import Container from '../Elements/Container'
import FormField from '../Elements/FormField'
import FrameTitle from '../Frame/FrameTitle'
import Input from '../Elements/Input'
import Textarea from '../Elements/Textarea'

export default function ChapterPageEdit({ id, pathSuffix, rec }) {

    const [modified, setModified] = useState(rec)

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const subject = {
        model: 'chapter',
        id,
    }

    const handleClose = () => {
        navigate(`${pathSuffix}`)
    }

    const handleCancel = () => {
        setModified(rec)
    }

    const handlePropSet = (prop, val) => {
        setModified({
            ...modified,
            [prop]: val
        })
    }


    const handlewhen = async ({ i, j, value }) => {
        let newWhen = [...modified.when]
        if (!newWhen[i]) newWhen[i] = []
        newWhen[i][j] = value || ''
        handlePropSet('when', newWhen)
    }

    const handleSave = async (e) => {
        e.preventDefault()
        try {
            await model.action('app.setLoading', true)

            const update = {
                id: rec._id,
                title: modified.title,
                body: modified.body,
                when: modified.when,
            }

            await model.action('chapter.update', {
                update,
            })

            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const isChanged = JSON.stringify(rec) !== JSON.stringify(modified)


    /*
    useEffect(() => {
        const onScroll = () => {
            heroBgRef.current.style.backgroundPosition = `center ${window.pageYOffset / 2}px`
        }
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    */

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('subject.getRecWithAssociations', { subject })
                await model.action('app.setLoading', false)
                /*
                setTimeout(() => {
                    scrollToChapter()
                }, 300)
                */
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id]) // eslint-disable-line

    return (
        <React.Fragment>
            <FrameTitle>
                <div className="flex flex-row items-center">
                    <ChapterIcon className="w-5 h-5 text-lime-400" />
                </div>
                <div className="text-white font-bold text-lg">
                    chapter
                </div>
                <div className="pl-2.5 py-0 bg-lime-600 rounded flex flex-row gap-1 items-center">
                    <div className=" text-white text-base font-semibold">
                        edit
                    </div>
                    <ButtonIcon className="" onClick={handleClose} theme="transparent">
                        <CloseIcon className="text-white/50 group-hover:text-white w-6 h-6" />
                    </ButtonIcon>
                </div>
            </FrameTitle>

            <div className="flex flex-col items-stretch bg-white">
                <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                    <FormField
                        label="title"
                        name="title"
                        size="md">
                        <Input className="text-2xl" placeholder="title" setValue={val => handlePropSet('title', val)} size="md" value={modified.title} />
                    </FormField>
                    <FormField
                        label="body"
                        name="body"
                        size="md">
                        <Textarea placeholder="body" setValue={val => handlePropSet('body', val)} size="md" value={modified.body} />
                    </FormField>
                    <FormField
                        label="when"
                        name="when"
                        size="md">
                        <div className="flex flex-row items-center gap-2">
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 0,
                                    j: 1,
                                    value: val
                                })} size="md" value={modified.when[0] && modified.when[0][1] ? modified.when[0][1] : ''} />
                            <div className="text-base ">/</div>
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 0,
                                    j: 0,
                                    value: val
                                })} size="md" value={modified.when[0] && modified.when[0][0] ? modified.when[0][0] : ''} />
                            <div className="text-base "> to </div>
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 1,
                                    j: 1,
                                    value: val
                                })} size="md" value={modified.when[1] && modified.when[1][1] ? modified.when[1][1] : ''} />
                            <div className="text-base ">/</div>
                            <Input placeholder="MM" setValue={val => handlewhen({
                                    i: 1,
                                    j: 0,
                                    value: val
                                })} size="md" value={modified.when[1] && modified.when[1][0] ? modified.when[1][0] : ''} />
                        </div>
                    </FormField>
                </Container>
                {isChanged && (
                    <div className={`w-full h-16 sticky bottom-0 z-20 flex flex-row gap-4 px-6 items-center grow bg-slate-950`}>
                        <EditIcon className="w-5 h-5 text-lime-400" />
                        <div className="text-white text-base font-semibold">save changes?</div>
                        <Button className="ml-auto" disabled={!isChanged} onClick={handleCancel} theme="">CANCEL</Button>
                        <Button className="" disabled={!isChanged} onClick={handleSave} theme="primary">SAVE</Button>
                    </div>
                )}
                
            </div>

        </React.Fragment>
    )
}