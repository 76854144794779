import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Route, Routes, useParams } from 'react-router-dom'

import { ReactComponent as LocationIcon } from '../../icon/location.svg'

import LocationPageDisplay from './LocationPageDisplay'
import LocationPageEdit from './LocationPageEdit'
import Container from '../Elements/Container'
import Frame from '../Frame'
import FrameTitle from '../Frame/FrameTitle'
import SubjectGrixis from '../SubjectGrixis'

import model from '../../model'

export default function LocationPage({ className }) {

    const { id } = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    const rec = useSelector(state => model.selector('location.recById', { state, id }))

    const subject = {
        model: 'location',
        id,
    }

    const pathSuffix = `/location/${id}`

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('location.getById', { id: subject.id })
                await model.action('app.setLoading', false)
                /*
                setTimeout(() => {
                    scrollToChapter()
                }, 300)
                */
            } catch (e) {
                await model.action('app.setLoading', false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [id, subject.id])

    if (!rec) {
        return (
            <Frame>
                <FrameTitle>
                    <div className="flex flex-row items-center">
                        <LocationIcon className="w-5 h-5 text-lime-400" />
                    </div>
                    <div className="text-white font-bold text-lg">
                        chapter
                    </div>
                </FrameTitle>
                <div className="w-full bg-white">
                    <Container className="flex flex-col gap-6 py-6 md:py-10 lg:py-12 xl:py-14">
                        <div className="animate-pulse h-6 bg-slate-300 rounded-full dark:bg-slate-600 w-[220px]" />
                        <div className="flex flex-col gap-2">
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[100%]" />
                            <div className="animate-pulse h-3 bg-slate-300 rounded-full dark:bg-slate-600 w-[60%]" />
                        </div>
                    </Container>
                </div>
                <SubjectGrixis key={id} subject={subject} pathSuffix={pathSuffix} />
            </Frame>
        )
    }

    return (
        <Frame>
            <Routes>
                <Route path="edit" element={<LocationPageEdit id={id} pathSuffix={pathSuffix} rec={rec} />} />
                <Route path="*" element={<LocationPageDisplay id={id} pathSuffix={pathSuffix} rec={rec} />} />
            </Routes>
            <SubjectGrixis key={id} subject={subject} pageSubject={subject} pathSuffix={pathSuffix} />
        </Frame>
    )
}