const collection = 'connection'

const fields = {
    _id: {
        type: 'obj',
    },
    created: {
        type: 'obj',
        shape: {
            when: {
                type: 'datetime',
            },
            who: {
                type: 'obj',
            },
        },
    },
    relation: {
        type: 'str', // appears, event, location, project, uploader, 
    },
    source: {
        type: 'obj',
        shape: {
            model: {
                type: 'str',
            },
            id: {
                type: 'obj',
            },
        }
    },
    subject: {
        type: 'obj',
        shape: {
            model: {
                type: 'str',
            },
            id: {
                type: 'obj',
            },
        }
    },

}

const model = 'connection'

const reducerPrefix = 'CONNECTION'
const reduxStore = 'connection'

const relations = {
    appears: {
        label: 'appearances by',
        subjectModel: 'member',
    },
    author: {
        label: 'author',
        subjectModel: 'member',
    },
    authored: {
        label: 'authored',
        subjectModel: 'member',
    },
    chapter: {
        label: 'in chapter',
        subjectModel: 'chapter',
    },
    developer: {
        label: 'developed by',
        subjectModel: 'member',
    },
    event: {
        label: 'during event',
        subjectModel: 'event',
    },
    location: {
        label: 'at location',
        subjectModel: 'location',
    },
    media: {
        label: 'media',
        subjectModel: 'media',
    },
    project: {
        label: 'on project',
        subjectModel: 'project',
    },
    uploaded: {
        label: 'uploaded',
        subjectModel: 'member',
    },
    uploader: {
        label: 'uploaded by',
        subjectModel: 'member',
    },
}

const subjectRelations = {
    chapter: ['appears', 'author', 'event', 'location', 'project'],
    event: ['appears', 'chapter', 'location', 'media', 'project'],
    location: ['appears', 'chapter', 'event', 'media', 'project'],
    media: ['appears', 'chapter', 'event', 'location', 'project', 'uploader'],
    member: ['authored', 'chapter', 'event', 'location', 'media', 'project', 'uploaded'],
    project: ['chapter', 'developer', 'event', 'location', 'media', 'project'],
}

// connection relation ~ model
const twins = {
    'appears~chapter': 'chapter~member',
    'author~chapter': 'authored~member',
    'event~chapter': 'chapter~event',
    'location~chapter': 'chapter~location',
    'media~chapter': 'chapter~media',
    'project~chapter': 'chapter~project',

    'appears~media': 'media~member',
    'event~media': 'media~event',
    'location~media': 'media~location',
    'project~media': 'media~project',
    'uploader~media': 'uploaded~member',

    'event~member': 'appears~event',
    'location~member': 'appears~location',
    'project~member': 'developer~project',

    'location~event': 'event~location',
    'project~event': 'event~project',

    'project~location': 'location~project',
    'project~project': 'project~project',
}

const exp = {
    collection,
    fields,
    model,
    reducerPrefix,
    reduxStore,
    relations,
    subjectRelations,
    twins,
}

export default exp