import constants from './constants'
import model from '../index'
import store from '../../store'

import utilApi from '../../utility/api'
import localStorage from '../../utility/localStorage'
import cloudinary from '../../utility/cloudinary'

const activate = async ({ token }) => {
	const data = {
		action: 'member.activate',
		token,
	}

	const { authToken, member } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	if (!member || !authToken) throw new Error('member.activate: activation failed')

	// store authToken
	await setAuthValues({ token: authToken, member })
}

const add = async ({ about, email, firstName, lastName }) => {
	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		action: 'member.add',
		authToken,
		about,
		email,
		firstName,
		lastName,
	}

	const member = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: member,
		}
	})

	return member
}

const auth = async () => {
	const authToken = localStorage.get(constants.authTokenName)

	if (!authToken) return

	const data = {
		action: 'member.auth',
		token: authToken,
	}

	const { member } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	if (!member) throw new Error('member.auth: auth failed')

	// store authToken
	await setAuthValues({ token: authToken, member })
}

const connectionAdd = async ({ id, relation, subject }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: `${constants.model}.connectionAdd`,
		authToken,
		id,
		relation,
		subject,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}

const connectionRemove = async ({ id, relation, subject }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.update: no authToken found`)

	const data = {
		action: `${constants.model}.connectionRemove`,
		authToken,
		id,
		relation,
		subject,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}

const getAuthToken = async () => {
	return localStorage.get(constants.authTokenName)
}

const getAll = async () => {
	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		action: 'member.getAll',
		authToken,
	}

	const { recs } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_ALL_SET`,
		payload: {
			recs,
		}
	})
}

const getById = async ({ id }) => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'member.getById',
		authToken,
		id,
	}

	const rec = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})

	return rec
}

const getMetrics = async () => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
		action: 'member.getMetrics',
		authToken,
	}

	const { metrics } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_METRICS_SET`,
		payload: {
			metrics,
		}
	})

	return metrics
}

const getRecs = async (props) => {

	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		...props,
		action: 'member.getRecs',
		authToken,
	}

	const { recs } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_LIST_SET`,
		payload: {
			list: recs,
			...props,
		}
	})
}

const getRecsForPage = async ({ page = 1 }) => {
	const authToken = await model.action('member.getAuthToken')

	const data = {
        action: `${constants.model}.getRecsForPage`,
        authToken,
        page,
    }

	const recs = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	await model.action('media.storeDisplayMediaForRecs', {recs})

	store.dispatch({
		type: `${constants.reducerPrefix}_SET_RECS_BY_PAGE`,
		payload: {
			recs,
			page,
		}
	})

	return recs
}

const invite = async ({ email }) => {
	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		action: 'member.invite',
		authToken,
		email,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}

const login = async ({ email }) => {

	if (!email || email.length < 1) throw new Error('enter an email')

	const data = {
		action: 'member.login',
		email,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}

const logout = async () => {
	localStorage.set(constants.authTokenName, undefined)
	store.dispatch({
		type: `${constants.reducerPrefix}_AUTH_CLEAR`,
	})
}

const remove = async ({ id }) => {
	const authToken = await model.action('member.getAuthToken')
	if (!authToken) throw new Error(`${constants.model}.add: no authToken found`)

	const data = {
		action: `${constants.model}.remove`,
		authToken,

		id,
	}

	const added = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REMOVE`,
		payload: {
			id,
		}
	})

	return added
}

const requestJoin = async ({ answer, email }) => {
	const data = {
		action: 'member.requestJoin',
		answer,
		email,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}

const requestMessage = async ({ email, message }) => {
	const data = {
		action: 'member.requestMesage',
		email,
		message,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}

const requestUpdates = async ({ email }) => {
	const data = {
		action: 'member.requestUpdates',
		email,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}

const search = async ({ term }) => {
	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		action: 'member.search',
		authToken,
		term,
	}

	const { recs } = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_SEARCH_RECS_SET`,
		payload: {
			recs,
		}
	})
}

const sendUpdate = async () => {
	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		action: 'member.sendUpdate',
		authToken,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}


const setAuthValues = async ({ token, member }) => {
	if (token) localStorage.set(constants.authTokenName, token)
	if (member) {
		store.dispatch({
			type: `${constants.reducerPrefix}_REC_SET`,
			payload: {
				rec: member,
			}
		})
		store.dispatch({
			type: `${constants.reducerPrefix}_AUTH_SET`,
			payload: {
				authId: member._id,
			}
		})
	}
}

const storeRec = async ({ rec }) => {
	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec,
		}
	})
}

const update = async ({ id, about, firstName, displayMediaId, lastName }) => {
	const token = await getAuthToken()
	if (!token) throw new Error(`${constants.model}.update: no token found`)

	const data = {
		action: 'member.update',
		authToken: token,
		id,
		about,
		displayMediaId,
		firstName,
		lastName,
	}

	const updated = await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})

	store.dispatch({
		type: `${constants.reducerPrefix}_REC_SET`,
		payload: {
			rec: updated,
		}
	})
}


const updateImage = async ({ id, file }) => {

	const token = await getAuthToken()
	if (!token) throw new Error(`${constants.model}.updateImage: no token found`)

	const nowTs = new Date().getTime()

	const publicId = `member-${id}-${nowTs}`

	const imageData = await cloudinary.upload({
		file,
		publicId,
		preset: 'memberImage',
		type: 'image',
	})

	await update({ id, image: imageData })
}

const updateRoles = async () => {
	const authToken = localStorage.get(constants.authTokenName)

	const data = {
		action: 'member.updateRoles',
		authToken,
	}

	await utilApi.req({
		data,
		endpoint: 'action',
		method: 'post',
	})
}

model.registerActions(constants.model, {
	activate,
	add,
	auth,
	connectionAdd,
	connectionRemove,
	invite,
	login,
	logout,
	getAll,
	getAuthToken,
	getById,
	getMetrics,
	getRecs,
	getRecsForPage,
	remove,
	requestJoin,
	requestMessage,
	requestUpdates,
	search,
	sendUpdate,
	storeRec,
	update,
	updateImage,
	updateRoles,
})