import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

//import { ReactComponent as HeartEmptyIcon } from '../../icon/heart-empty.svg'
//import { ReactComponent as CommentIcon } from '../../icon/conversation.svg'

import DotMenu from '../Elements/DotMenu'
import Grixis from '../Grixis'
import Loader from '../Elements/Loader'
import Selector from '../Elements/Selector'
import SubjectGrix from '../SubjectGrix'
import SubjectGrixRow from '../SubjectGrixRow'

import model from '../../model'

const pageSize = 12

export default function SubjectGrixis({ subject, pathSuffix }) {
    const [subjectFocus, setSubjectFocus] = useState(null)
    const [loading, setLoading] = useState(false)
    const [relationActive, setRelationActive] = useState('all')

    const [curPageForRelationActive, setCurPageForRelationActive] = useState({})
    const pagesLoadingForRelationActive = useRef({})
    const pagesLoadingForRelationActivePosition = useRef({})

    const rec = useSelector(state => model.selector('subject.rec', { state, subject }))
    //const connectionsLoadOnce = useRef(false)

    //const displayMediaId = rec && rec.displayMediaId ? rec.displayMediaId : null

    const total = rec ? model.helper('connection.getCountForConnections', { connections: rec.connections }) : 0

    const ordered = model.helper('connection.getOrderByPriorityForRecRelationActive', { rec, relationActive })
    const connectionPages = model.helper('connection.getPageForSubjects', { pageSize, subjects: ordered })

    const refBottom = useRef()

    /*
    const actions = [{
        label: 'edit connections',
        onClick: async () => {
            await model.action('app.setModal', {
                modal: 'connectionsEdit',
                connections: rec && rec.connections ? rec.connections : {},
                subject,
            })
        }
    }]
        */

    const actions = []

    const handleRelationClick = ({ relation }) => {
        setRelationActive(relation)
    }

    const handleSubjectClick = ({ subject }) => {
        setSubjectFocus({
            intent: 'display',
            subject
        })
    }

    const handleClose = ({ subject }) => {
        setSubjectFocus(null)
    }

    const handleEdit = ({ subject }) => {
        setSubjectFocus({
            intent: 'edit',
            subject
        })
    }

    useEffect(()=>{
        pagesLoadingForRelationActivePosition.current[relationActive] = pagesLoadingForRelationActivePosition.current[relationActive] || {}
        pagesLoadingForRelationActivePosition.current[curPageForRelationActive[relationActive]] = refBottom.current.offsetTop
    }, [relationActive]) //eslint-disable-line

    useEffect(() => {
        const onScroll = () => {
            const rect = refBottom.current.getBoundingClientRect();
            const height = window.innerHeight
            const contentHeight = refBottom.current.offsetTop

            //console.log('height:', height, 'rect.top', rect.top)
            if (height + 100 > rect.top) {

                const curPage = curPageForRelationActive[relationActive] || 1
                const nextPage = curPage + 1

                pagesLoadingForRelationActivePosition.current[relationActive] = pagesLoadingForRelationActivePosition.current[relationActive] || {}

                if (pagesLoadingForRelationActivePosition.current[relationActive][curPage] >= contentHeight) return

                if (!pagesLoadingForRelationActivePosition.current[relationActive][nextPage]){
                    pagesLoadingForRelationActivePosition.current[relationActive][nextPage] = true

                    setCurPageForRelationActive({
                        ...curPageForRelationActive,
                        [relationActive]: nextPage,
                    })
                }
            }
            //console.log(window.scrollY, rect.top)
        }
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [relationActive, curPageForRelationActive])

    useEffect(() => {
        const curPage = curPageForRelationActive[relationActive] || 1
        if (!connectionPages || !connectionPages[curPage]) return

        if (curPage > 1 && (!connectionPages[curPage - 1] || connectionPages[curPage - 1].length < 1)) return

        if (pagesLoadingForRelationActive.current[relationActive] && pagesLoadingForRelationActive.current[relationActive].includes(curPage)) return
        pagesLoadingForRelationActive.current[relationActive] = pagesLoadingForRelationActive.current[relationActive] || []
        pagesLoadingForRelationActive.current[relationActive].push(curPage);

        (async () => {
            try {
                setLoading(true)
                await model.action('subject.getSubjectsRecs', { subjects: connectionPages[curPage] })
                setLoading(false)
            } catch (e) {
                setLoading(false)
                const error = model.helper('app.getErrorMessage', e)
                await model.action('app.setAlert', {
                    message: error,
                    theme: 'danger'
                })
            }
        })()
    }, [connectionPages, curPageForRelationActive, relationActive])

    const renderContentPages = () => {
        const curPage = curPageForRelationActive[relationActive] || 1

        let subjects = []
        for (let i = 1; i <= curPage; i++) {
            if (!connectionPages[i]) break;
            let subjectsPage = Object.values(connectionPages[i]).map(sub => {
                const size = [1, 1]

                if (subjectFocus && (model.helper('subject.isMatch', { subject1: sub, subject2: subjectFocus.subject }))) {
                    return (
                        <React.Fragment key={`${sub.model}~${sub.id}`}>
                            <SubjectGrix
                                handleClick={handleSubjectClick}
                                handleEdit={handleEdit}
                                key={`${sub.model}~${sub.id}`}
                                subject={sub}
                                selected={true}
                                size={size} />
                            <SubjectGrixRow
                                handleClose={handleClose}
                                handleRemoved={()=> ({subject: sub})}
                                key={`row~${sub.model}~${sub.id}`}
                                intent={subjectFocus.intent}
                                pageSubject={subject}
                                subject={sub} />
                        </React.Fragment>
                    )
                }
                return (
                    <SubjectGrix
                        key={`${sub.model}~${sub.id}`}
                        deselected={subjectFocus ? true : false}
                        handleClick={handleSubjectClick}
                        handleEdit={handleEdit}
                        subject={sub}
                        size={size}
                    />
                )
            })

            subjects = [...subjects, ...subjectsPage]
        }
        return subjects
    }

    let options = [{
        count: total,
        label: 'related',
        value: 'all'
    }]

    rec && rec.connections && Object.keys(rec.connections).forEach((relation, i) => {
        const count = rec.connections[relation].length
        options.push({
            count,
            label: model.helper('connection.getRelationLabel', { relation }),
            value: relation
        })
    })

    let selected
    if (relationActive === 'all'){
        selected = options[0]
    } else {
        selected = options.find(option => {
            return option.value === relationActive
        })
    }

    /*

    <div className={`${relationActive === 'love' ? ' border-b-rose-500' : ''} flex flex-row group items-center h-16 gap-2 cursor-pointer border-y-4 border-transparent`} onClick={() => handleRelationClick({ relation: 'love' })}>
                    <span className="font-semibold text text-base text-white/80 group-hover:text-white">
                        <HeartEmptyIcon className="w-5 h-5 text-rose-500"/>
                    </span>
                    <span className="font-semibold text text-sm text-white/80 group-hover:text-white">
                        {total}
                    </span>
                </div>
                <div className={`${relationActive === 'comment' ? ' border-b-lime-500' : ''} flex flex-row group items-center h-16 gap-2 cursor-pointer border-y-4 border-transparent`} onClick={() => handleRelationClick({ relation: 'comment' })}>
                    <span className="font-semibold text text-base text-white/80 group-hover:text-white">
                        <CommentIcon className="w-5 h-5 text-white"/>
                    </span>
                    <span className="font-semibold text text-sm text-lime-500/80 group-hover:text-lime-500">
                        {total}
                    </span>
                </div>
    */

    return (
        <React.Fragment>
            <div className="p-3 bg-slate-900 md:hidden">
                <Selector onChange={option => handleRelationClick({ relation: option.value })} options={options} selected={selected}/>
            </div>
            <div className="hidden md:flex flex-row items-center px-6 gap-6 bg-slate-900">
                
                <div className={`${relationActive === 'all' ? ' border-b-lime-500' : ''} flex flex-row group items-center h-16 gap-2 cursor-pointer border-y-4 border-transparent`} onClick={() => handleRelationClick({ relation: 'all' })}>
                    <span className="font-semibold text text-base text-white/80 group-hover:text-white">
                        connected to
                    </span>
                    <span className="font-semibold text text-sm text-lime-500/80 group-hover:text-lime-500">
                        {total}
                    </span>
                </div>
                {rec && rec.connections && Object.keys(rec.connections).map((relation, i) => {
                    const count = rec.connections[relation].length
                    return (
                        <div key={relation} className={`${relationActive === relation ? 'border-b-lime-500' : ''} flex flex-row group items-center h-16 gap-2 cursor-pointer border-y-4 border-transparent`} onClick={() => handleRelationClick({ relation })}>
                            <span className="font-semibold text text-base text-white/80 group-hover:text-white">
                                {model.helper('connection.getRelationLabel', { relation })}
                            </span>
                            <span className="font-semibold text text-sm text-lime-500/80 group-hover:text-lime-500">
                                {count}
                            </span>
                        </div>
                    )
                })}
                <DotMenu direction="right" options={actions} theme="light" />
            </div>
            <div className="bg-slate-900">
                <Grixis>
                    {renderContentPages()}
                </Grixis>
            </div>
            {loading && (
                <div className="h-[300px] relative">
                    <div className={`${loading ? 'opacity-100' : 'opacity-0 pointer-events-none'} flex flex-row items-center justify-center absolute top-0 bottom-0 left-0 right-0 bg-slate-900/80`}>
                        <Loader />
                    </div>
                </div>
            )}

            <div ref={refBottom} />
        </React.Fragment>
    )
}